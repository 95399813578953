import { Wrapper } from 'components/index.s'
import { WrapperError } from './index.s'
import ErrorIcon from "../../assets/icons/error-icon.svg";
import { useTranslation } from 'react-i18next';

type Props = {}

const TokenError = (props: Props) => {
  const {t} = useTranslation();
  return (
    <Wrapper>
      <WrapperError>
         <img
            src={ErrorIcon as unknown as string}
            width={200}
            height={200}
            alt="logo"
          />
        <h2>{t("Error.2")}</h2>
      </WrapperError>
    </Wrapper>
  )
}

export default TokenError